import React from "react";
import { Helmet } from "react-helmet";
import CricketNavBar from "../../../Components/SportsNavBar/CricketNavbar";
import { Col, Container, Image, Navbar, Row } from "react-bootstrap";
import myLogo from "../../../Images/my_logo4.png";
import gg from "../../../Images/ggg.png";
import { Link } from "react-router-dom";
const Ipl = () => {
  return (
    <div>
      <Helmet></Helmet>
      <div className="d-none d-md-none d-lg-block">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container fluid></Container>
        </Navbar>
        <Navbar className="">
          <Container className="d-flex justify-content-center">
            <Navbar.Brand as={Link} to="/home">
              <img
                src={myLogo}
                alt=""
                className="img-fluid"
                style={{ height: "100px" }}
              />
            </Navbar.Brand>
          </Container>
        </Navbar>
        <Navbar bg="light" data-bs-theme="light">
          <Container
            fluid
            className="d-flex justify-content-start border-bottom"
          >
            <h5 className="ps-2 fw-bold">
              SPORTS <span className="text-warning">CRICKET</span> IPL
            </h5>
          </Container>
        </Navbar>
        <Navbar bg="light" data-bs-theme="light" className="mt-0 pt-0 ">
          <Container fluid className="d-flex justify-content-left">
            {/* <Nav className="d-flex justify-content-left fw-bold">
              <Nav.Item>
                <Nav.Link as={Link} to="cricket-home">
                  Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="ipl">
                  IPL
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="t20">
                  T20
                </Nav.Link>
              </Nav.Item>
            </Nav> */}
          </Container>
        </Navbar>
      </div>
      <Container fluid>
        <Row>
          <Col lg={9}>
            {" "}
            {/* <Image
              className=""
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTs8Vn6wiQiwr5t3qKaDK7Cj3BYuG5ts2mF1Q&s"
              height={"320px"}
            /> */}
            <div className="row g-1 d-flex align-itens-center">
              <div className="col-lg-1">
                <Image
                  className=""
                  src="https://a.espncdn.com/combiner/i?img=/i/leaguelogos/cricket/500/8048.png&w=80&h=80&transparent=true"
                  width={"60px"}
                />
              </div>
              <div className="col-lg-10 d-flex align-itens-center">
                <div
                  className="py-3"
                  style={
                    {
                      // backgroundImage: "url('/path-to-your-image.jpg')",
                      // backgroundSize: "cover",
                      // backgroundPosition: "center",
                    }
                  }
                >
                  <h5 className="mb-0 pb-0">Indian Premier League 2024</h5>
                </div>
              </div>
            </div>
            <Row className="px-lg-3 ">
              <Col md={12}>
                <h5 className="m-0 pb-1">Match Results</h5>
              </Col>
              <Col md={6}>
                <Image className="" src={gg} height={"160px"} />
              </Col>
              <Col
                md={6}
                className=" p-3 rounded-2 border"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                <div>
                  <small
                    className="mb-1 text-dark"
                    style={{ fontSize: "14px" }}
                  >
                    1st Match, Chennai, Mar 22 2024
                  </small>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <p
                      className="mb-1 pb-0 fw-bold text-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335970.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Royal Challengers Bangaluru{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <small className="mb-1 fw-bold text-secondary text-end">
                      173/6
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <p
                      className="mb-1 fw-bold text-dark"
                      style={{ fontSize: "14px" }}
                    >
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335974.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Chennai Super Kings{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <small className="mb-1 fw-bold text-dark text-end">
                      176/4
                    </small>
                  </div>
                </div>
              </Col>
              <Col
                md={6}
                className=" p-3 rounded-2 border"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                <div>
                  <small
                    className="mb-1 text-dark"
                    style={{ fontSize: "14px" }}
                  >
                    2nd Match, Chennai, Mar 22 2024
                  </small>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <p
                      className="mb-1 pb-0 fw-bold text-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335975.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Delhi Capitals{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <small className="mb-1 fw-bold text-secondary text-end">
                      173/6
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <p
                      className="mb-1 fw-bold text-dark"
                      style={{ fontSize: "14px" }}
                    >
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335973.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Punjab Kings{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <small className="mb-1 fw-bold text-dark text-end">
                      176/4
                    </small>
                  </div>
                </div>
              </Col>
              <Col
                md={6}
                className=" p-3 rounded-2 border"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                <div>
                  <small
                    className="mb-1 text-dark"
                    style={{ fontSize: "14px" }}
                  >
                    3rd Match, Chennai, Mar 22 2024
                  </small>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <p
                      className="mb-1 pb-0 fw-bold text-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335971.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Kolkata Knight Riders{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <small className="mb-1 fw-bold text-secondary text-end">
                      173/6
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <p
                      className="mb-1 fw-bold text-dark"
                      style={{ fontSize: "14px" }}
                    >
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/628333.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Sunrisers Hyderabad{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <small className="mb-1 fw-bold text-success text-end">
                      176/4
                    </small>
                  </div>
                </div>
              </Col>
              <Col
                md={6}
                className=" p-3 rounded-2 border"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                <div>
                  <small
                    className="mb-1 text-dark"
                    style={{ fontSize: "14px" }}
                  >
                    4th Match, Chennai, Mar 22 2024
                  </small>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <p
                      className="mb-1 pb-0 fw-bold text-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335977.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Rajasthan Royals{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <small className="mb-1 fw-bold text-secondary text-end">
                      173/6
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-9">
                    <p
                      className="mb-1 fw-bold text-dark"
                      style={{ fontSize: "14px" }}
                    >
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/1298768.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Lucknow Super Giants{" "}
                    </p>
                  </div>

                  <div className="col-lg-1">
                    <small className="mb-1 fw-bold text-dark text-end">
                      <i class="fa-solid fa-trophy"></i>
                    </small>
                  </div>
                  <div className="col-lg-1">
                    <small className="mb-1 fw-bold text-dark text-end">
                      176/4
                    </small>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <h5 className="m-0 pt-3">Schedule</h5>
              </Col>
              <div>
                <small
                  className="mb-1 text-primary"
                  style={{ fontSize: "14px" }}
                >
                  Friday, October 4, 2024
                </small>
              </div>
              <Col md={12} className="bg-white p-2 px-3 rounded-2 border">
                <small style={{ fontSize: "10px" }} className="fw-bold">
                  MATCH UP
                </small>
                <div className="row">
                  <div className="col-lg-3">
                    <p className="mb-1 pb-0 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335977.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Rajasthan Royals{" "}
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p className="mb-1 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/1298768.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Lucknow Super Giants{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <p className="mb-1 ">5.30 PM</p>
                  </div>
                  <div className="col-lg-4">
                    <p className="mb-1 ">Chennai, TamilNadu, India</p>
                  </div>
                </div>
                <div className="row"></div>
              </Col>
              <Col md={12} className="bg-white py-2 px-3 rounded-2 border">
                <small style={{ fontSize: "10px" }} className="fw-bold">
                  MATCH UP
                </small>
                <div className="row">
                  <div className="col-lg-3">
                    <p className="mb-1 pb-0 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335975.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Delhi Capitals{" "}
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p className="mb-1 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335973.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Punjab Kings{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <p className="mb-1 ">5.30 PM</p>
                  </div>
                  <div className="col-lg-4">
                    <p className="mb-1 ">Chennai, TamilNadu, India</p>
                  </div>
                </div>
                <div className="row"></div>
              </Col>
              <div>
                <small
                  className="mb-1 text-primary"
                  style={{ fontSize: "14px" }}
                >
                  Monday, October 10, 2024
                </small>
              </div>
              <Col md={12} className="bg-white p-2 px-3 rounded-2 border">
                <small style={{ fontSize: "10px" }} className="fw-bold">
                  MATCH UP
                </small>
                <div className="row">
                  <div className="col-lg-3">
                    <p className="mb-1 pb-0 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335977.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Rajasthan Royals{" "}
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p className="mb-1 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/1298768.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Lucknow Super Giants{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <p className="mb-1 ">5.30 PM</p>
                  </div>
                  <div className="col-lg-4">
                    <p className="mb-1 ">Chennai, TamilNadu, India</p>
                  </div>
                </div>
                <div className="row"></div>
              </Col>
              <Col md={12} className="bg-white py-2 px-3 rounded-2 border">
                <small style={{ fontSize: "10px" }} className="fw-bold">
                  MATCH UP
                </small>
                <div className="row">
                  <div className="col-lg-3">
                    <p className="mb-1 pb-0 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335975.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Delhi Capitals{" "}
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p className="mb-1 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335973.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Punjab Kings{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <p className="mb-1 ">5.30 PM</p>
                  </div>
                  <div className="col-lg-4">
                    <p className="mb-1 ">Chennai, TamilNadu, India</p>
                  </div>
                </div>
                <div className="row"></div>
              </Col>
              <Col md={12} className="bg-white py-2 px-3 rounded-2 border">
                <small style={{ fontSize: "10px" }} className="fw-bold">
                  MATCH UP
                </small>
                <div className="row">
                  <div className="col-lg-3">
                    <p className="mb-1 pb-0 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335975.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Delhi Capitals{" "}
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p className="mb-1 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335973.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Punjab Kings{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <p className="mb-1 ">5.30 PM</p>
                  </div>
                  <div className="col-lg-4">
                    <p className="mb-1 ">Chennai, TamilNadu, India</p>
                  </div>
                </div>
                <div className="row"></div>
              </Col>
              <div>
                <small
                  className="mb-1 text-primary"
                  style={{ fontSize: "14px" }}
                >
                  Friday, October 4, 2024
                </small>
              </div>
              <Col md={12} className="bg-white p-2 px-3 rounded-2 border">
                <small style={{ fontSize: "10px" }} className="fw-bold">
                  MATCH UP
                </small>
                <div className="row">
                  <div className="col-lg-3">
                    <p className="mb-1 pb-0 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335977.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Rajasthan Royals{" "}
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p className="mb-1 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/1298768.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Lucknow Super Giants{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <p className="mb-1 ">5.30 PM</p>
                  </div>
                  <div className="col-lg-4">
                    <p className="mb-1 ">Chennai, TamilNadu, India</p>
                  </div>
                </div>
                <div className="row"></div>
              </Col>
              <Col md={12} className="bg-white py-2 px-3 rounded-2 border">
                <small style={{ fontSize: "10px" }} className="fw-bold">
                  MATCH UP
                </small>
                <div className="row">
                  <div className="col-lg-3">
                    <p className="mb-1 pb-0 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335975.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Delhi Capitals{" "}
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p className="mb-1 ">
                      <Image
                        className=""
                        src="https://a1.espncdn.com/combiner/i?img=/i/teamlogos/cricket/500/335973.png&h=35&w=35"
                        width={"20px"}
                      />{" "}
                      Punjab Kings{" "}
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <p className="mb-1 ">5.30 PM</p>
                  </div>
                  <div className="col-lg-4">
                    <p className="mb-1 ">Chennai, TamilNadu, India</p>
                  </div>
                </div>
                <div className="row"></div>
              </Col>
            </Row>
          </Col>
          <Col lg={3} md={3} className="mt-3">
            <div className="d-flex justify-content-center">
              <small>Advertisement</small>
            </div>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2 border"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                fluid
              />
            </Link>
            {/*
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <Image
                  className="mx-auto d-block pt-2 border"
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+And+Red+Bold+Ganesh+Chaturthi+Instagram+Post.gif"
                  fluid
                />
              </Link>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <Image
                  className="mx-auto d-block pt-2 border"
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Video+Wishing+Happy+Ganesh+Chaturthi+Whatsapp+Story.jpg"
                  fluid
                />
              </Link> */}
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2 border"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Maroon+traditional+Minimal+Guru+Purnima+Greeting+Instagram+Post.gif"
                fluid
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Ipl;
