import React from "react";
import IntakeNavbar from "../../Components/IntakeNavbar/IntakeNavbar";

const FeederHome = () => {
  return (
    <div>
      <IntakeNavbar />
    </div>
  );
};

export default FeederHome;
