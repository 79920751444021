import React from "react";
import { Helmet } from "react-helmet";
import CricketNavBar from "../../../Components/SportsNavBar/CricketNavbar";

const Cricket = () => {
  return (
    <div>
      <Helmet></Helmet>
      <CricketNavBar />
    </div>
  );
};

export default Cricket;
