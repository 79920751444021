import React, { useState, useRef } from "react";
import axios from "axios";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import IntakeNavbar from "../../Components/IntakeNavbar/IntakeNavbar";

const SportsFeeder = () => {
  const [formData, setFormData] = useState({
    BA_Category: "",
    BA_Status: "",
    BA_Priority: "",
    SP_Category: "",
    SP_Title: "",
    SP_Lead: "",
    SP_Description: "",
    SP_StartDate: "",
    SP_EndDate: "",
    SP_Attendees: "",
    SP_RegistrationLink: "",
    SP_City: "",
    SP_State: "",
    SP_Country: "",
    SP_Postalcode: "",
    SP_VenueName: "",
    SP_Organizer: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef();

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataWithImage = new FormData();
    for (const key in formData) {
      formDataWithImage.append(key, formData[key]);
    }
    formDataWithImage.append("SP_Image", imageFile);

    try {
      const response = await axios.post(
        "http://3.143.153.138/api/sports",
        formDataWithImage,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response.data);
      setFormData({
        BA_Category: "",
        BA_Status: "",
        BA_Priority: "",
        SP_Category: "",
        SP_Title: "",
        SP_Lead: "",
        SP_Description: "",
        SP_StartDate: "",
        SP_EndDate: "",
        SP_Attendees: "",
        SP_RegistrationLink: "",
        SP_City: "",
        SP_State: "",
        SP_Country: "",
        SP_Postalcode: "",
        SP_VenueName: "",
        SP_Organizer: "",
      });
      setImageFile(null);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [sportEventIdToDelete, setSportEventIdToDelete] = useState("");

  const handleDeleteChange = (e) => {
    setSportEventIdToDelete(e.target.value);
  };

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `http://3.143.153.138/api/sports/${sportEventIdToDelete}`
      );
      console.log(response.data);
      setSportEventIdToDelete("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Container fluid>
      <IntakeNavbar />
      <Container className="my-4">
        <h2 className="text-center text-primary">Sports Feeder</h2>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>BA Category</Form.Label>
                <Form.Control
                  type="text"
                  name="BA_Category"
                  value={formData.BA_Category}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>BA Status</Form.Label>
                <Form.Control
                  type="text"
                  name="BA_Status"
                  value={formData.BA_Status}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>BA Priority</Form.Label>
                <Form.Control
                  type="number"
                  name="BA_Priority"
                  value={formData.BA_Priority}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Sports Category</Form.Label>
                <Form.Control
                  type="text"
                  name="SP_Category"
                  value={formData.SP_Category}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Sports Title</Form.Label>
                <Form.Control
                  type="text"
                  name="SP_Title"
                  value={formData.SP_Title}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Sports Lead</Form.Label>
                <Form.Control
                  type="text"
                  name="SP_Lead"
                  value={formData.SP_Lead}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Sports Description</Form.Label>
            <Form.Control
              as="textarea"
              name="SP_Description"
              value={formData.SP_Description}
              onChange={handleChange}
            />
          </Form.Group>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="SP_StartDate"
                  value={formData.SP_StartDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="SP_EndDate"
                  value={formData.SP_EndDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Sports Image</Form.Label>
            <Form.Control
              type="file"
              ref={fileInputRef}
              onChange={handleImageChange}
            />
          </Form.Group>
          <Row className="text-center">
            <Col>
              <Button type="submit" variant="primary">
                Add Sports Event
              </Button>
            </Col>
          </Row>
        </Form>
        <hr />
        <h3>Delete Sports Event</h3>
        <Form onSubmit={handleDeleteSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Event ID</Form.Label>
            <Form.Control
              type="text"
              name="sportEventIdToDelete"
              value={sportEventIdToDelete}
              onChange={handleDeleteChange}
            />
          </Form.Group>
          <Button type="submit" variant="danger">
            Delete Event
          </Button>
        </Form>
      </Container>
    </Container>
  );
};

export default SportsFeeder;
