import React from "react";
import { Helmet } from "react-helmet";
import NavBar from "../../Components/NavBar/NavBar";
import HomePage from "../../Components/HomePage/HomePage";

const Home = () => {
  return (
    <div>
      <Helmet></Helmet>
      <NavBar />
      <HomePage />
    </div>
  );
};

export default Home;
