import React from "react";
import PropTypes from "prop-types";
import styles from "./IntakeNavbar.module.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import myLogo from "../../Images/my_logo4.png";

const IntakeNavbar = () => (
  <div className={styles.IntakeNavbar}>
    <div className="d-none d-md-none d-lg-block">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid></Container>
      </Navbar>
      <Navbar className="">
        <Container className="d-flex justify-content-center">
          <Navbar.Brand as={Link} to="/home">
            <img
              src={myLogo}
              alt=""
              className="img-fluid"
              style={{ height: "100px" }}
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Navbar bg="light" data-bs-theme="light">
        <Container fluid className="d-flex justify-content-start border-bottom">
          <h5 className="ps-2 fw-bold">Input Feeder</h5>
        </Container>
      </Navbar>
      <Navbar bg="light" data-bs-theme="light" className="mt-0 pt-0 ">
        <Container fluid className="d-flex justify-content-left">
          <Nav className="d-flex justify-content-left fw-bold">
            <Nav.Item>
              <Nav.Link as={Link} to="/feeder/homemaster">
                HomeMaster
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/feeder/events">
                Events
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/feeder/sports">
                Sports
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/feeder/healthmaster">
                Health
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
      <Outlet />
    </div>
  </div>
);

IntakeNavbar.propTypes = {};

IntakeNavbar.defaultProps = {};

export default IntakeNavbar;
