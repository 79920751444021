import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Cricket from "./Cricket/Cricket";
import NFL from "./NFL/NFL";
import SportsHome from "./SportsHome";
import T20 from "./Cricket/T20";
import Ipl from "./Cricket/Ipl";

const Sports = () => {
  return (
    // <div className="py-4 fw-bold text-center">
    //   <h5>Page Under Development</h5>
    // </div>
    <div>
      <Routes>
        {" "}
        <Route index element={<SportsHome />} />
        <Route path="cricket" element={<Cricket />} />
        <Route path="/cricket/t20" element={<T20 />} />
        <Route path="/cricket/ipl" element={<Ipl />} />
        <Route path="nfl" element={<NFL />} />
      </Routes>
      <Outlet />
    </div>
  );
};

export default Sports;
