import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, NavLink } from "react-bootstrap";
import URLs from "../ExpressURL/ExpressURL";
import Advertisement from "../../Components/Advertisement/Advertisement";
import { Link } from "react-router-dom";

const HomeEvents = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(URLs.HomeEventURL);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();

        // Filter and sort the last 6 items in descending order
        const processedData = result
          .slice(-6) // Get the last 6 items
          .sort((a, b) => b.id - a.id); // Sort in descending order (adjust key as needed)

        setData(processedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Helper function to truncate text
  const truncateText = (text, maxLength) => {
    if (!text) return ""; // Check if text is null or undefined

    return text.length > maxLength ? text.substr(0, maxLength) + "..." : text;
  };

  return (
    <div className="bg-light">
      <Container fluid>
        <Row>
          <Col lg={9}>
            <h4 className="fw-bold pt-2 my-3">Trending Events</h4>
            <Row>
              {data &&
                data.map((item, index) => (
                  <Col lg={6} md={12} className="mb-4" key={index}>
                    <NavLink as={Link} to={`events/${item.id}`}>
                      <Row className="g-3 shadow-sm">
                        <Col lg={5} md={4} xs={12}>
                          <img
                            src={item.EV_Image}
                            alt={item.EV_Title}
                            className="img-fluid mb-2"
                            style={{
                              minHeight: "120px",
                              objectFit: "cover",
                              width: "100%",
                            }}
                          />
                        </Col>
                        <Col lg={7} md={8} xs={12}>
                          <div>
                            <small
                              style={{ fontSize: "10px" }}
                              className="text-uppercase text-primary fw-bold"
                            >
                              {item.EV_Country}
                            </small>
                            <h6 className="fw-bold  pb-0 mb-0">
                              {truncateText(`${item.EV_Title} `, 28)}
                            </h6>
                            <p style={{ lineHeight: "20px" }} className="">
                              {truncateText(`${item.EV_Lead} `, 90)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </NavLink>
                  </Col>
                ))}
            </Row>
          </Col>
          <Col sm={12} md={12} lg={3}>
            <div className="text-center p-2">
              <small>Advertisement</small>
              <Image
                className="mx-auto d-block"
                src={Advertisement.HomeEventAd_600x300}
                fluid
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeEvents;
