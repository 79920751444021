import React, { useEffect, useState } from "react";
import { Container, Row, Col, NavLink } from "react-bootstrap";
import URLs from "../ExpressURL/ExpressURL";
import { Link } from "react-router-dom";

const HomeMaster = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(URLs.HomeMasterURL);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();

        // Filter and sort the last 4 items in descending order
        const processedData = result
          .slice(-4) // Get the last 4 items
          .sort((a, b) => b.id - a.id); // Sort in descending order (adjust key as needed)

        setData(processedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Helper function to truncate text
  const truncateText = (text, maxLength) => {
    if (!text) return ""; // Check if text is null or undefined

    return text.length > maxLength ? text.substr(0, maxLength) + "..." : text;
  };

  return (
    <div>
      <Container fluid>
        <h4 className="fw-bold my-3">Top Picks</h4>
        <Row>
          {data && data.length > 0 && (
            <Col lg={6} className="mb-4">
              <NavLink as={Link} to={`/news/${data[0].id}`}>
                <div className="">
                  <img
                    src={data[0].HM_Image}
                    alt={data[0].HM_Title}
                    className="img-fluid mb-2"
                  />
                  <h6 className="fw-bold">
                    {truncateText(`${data[0].HM_Title} `, 80)}
                  </h6>
                  <p style={{ lineHeight: "20px" }}>
                    {truncateText(`${data[0].HM_Lead} `, 180)}
                  </p>
                </div>
              </NavLink>
            </Col>
          )}

          {/* Second column for the remaining items */}
          {data && data.length > 1 && (
            <Col lg={6}>
              {data.slice(1).map((item, index) => (
                <NavLink as={Link} to={`/news/${item.id}`}>
                  <Row key={index} className="mb-1">
                    <Col lg={5} className="">
                      <img
                        src={item.HM_Image}
                        alt={item.HM_Title}
                        className="img-fluid mb-2"
                      />
                    </Col>
                    <Col lg={7}>
                      <div className="">
                        <h6 className="fw-bold">
                          {truncateText(`${item.HM_Title} `, 70)}
                        </h6>
                        <p style={{ lineHeight: "20px" }}>
                          {truncateText(`${item.HM_Lead} `, 120)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </NavLink>
              ))}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default HomeMaster;
