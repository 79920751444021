import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import FeederHome from "./FeederHome";
import EventsFeeder from "./EventsFeeder";
import HomeMasterFeeder from "./HomeMasterFeeder";
import SportsFeeder from "./SportsFeeder";
import HealthMasterFeeder from "./HealthMasterFeeder";

const Feeder = () => {
  return (
    <div>
      <Routes>
        <Route index element={<FeederHome />} />
        <Route path="homemaster" element={<HomeMasterFeeder />} />
        <Route path="events" element={<EventsFeeder />} />
        <Route path="sports" element={<SportsFeeder />} />
        <Route path="healthmaster" element={<HealthMasterFeeder />} />
      </Routes>
      <Outlet />
    </div>
  );
};

export default Feeder;
