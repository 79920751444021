import React from "react";
import { Helmet } from "react-helmet";
import SportsNavBar from "../../Components/SportsNavBar/SportsNavBar";

const SportsHome = () => {
  return (
    <div>
      <Helmet></Helmet>
      <SportsNavBar />
      storts home
    </div>
  );
};

export default SportsHome;
