import React from "react";
import PropTypes from "prop-types";
import styles from "./CricketNavBar.module.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import myLogo from "../../Images/my_logo4.png";
import { Link } from "react-router-dom";

const CricketNavBar = () => (
  <div className={styles.CricketNavBar}>
    <div className="d-none d-md-none d-lg-block">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid></Container>
      </Navbar>
      <Navbar className="">
        <Container className="d-flex justify-content-center">
          <Navbar.Brand as={Link} to="/home">
            <img
              src={myLogo}
              alt=""
              className="img-fluid"
              style={{ height: "100px" }}
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Navbar bg="light" data-bs-theme="light">
        <Container fluid className="d-flex justify-content-start border-bottom">
          <h5 className="ps-2 fw-bold">
            SPORTS <span className="text-warning">CRICKET</span>
          </h5>
        </Container>
      </Navbar>
      <Navbar bg="light" data-bs-theme="light" className="mt-0 pt-0 ">
        <Container fluid className="d-flex justify-content-left">
          <Nav className="d-flex justify-content-left fw-bold">
            <Nav.Item>
              <Nav.Link as={Link} to="cricket-home">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="ipl">
                IPL
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="t20">
                T20
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
    </div>
  </div>
);

CricketNavBar.propTypes = {};

CricketNavBar.defaultProps = {};

export default CricketNavBar;
