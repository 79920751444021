// Height x Width

// HomePage.....................................................................
const HomeEventAd_600x300 =
  "https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif";
const HomeSportAd_600x300 =
  "https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif";

const Advertisement = {
  HomeEventAd_600x300,
  HomeSportAd_600x300,
};

export default Advertisement;
