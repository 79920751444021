//import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { GeoLocationProvider } from "./Components/GeoLocation";
import { FlagProvider } from "./Components/FlagContext";
import Home from "./Pages/Home/Home";
import Sports from "./Pages/Sports/Sports";
import Technology from "./Pages/Technology/Technology";
import Events from "./Pages/Eventss/Events";
import Health from "./Pages/Health/Health";
import Entertainment from "./Pages/Entertainment/Entertainment";
import Immigiration from "./Pages/Immigiration";
import SportsDetail from "./Components/SportsDetail";
import MovieDetails from "./Components/MovieDetails";
import MasterDetails from "./Components/MasterDetails";
import ImmigirationVisa from "./Pages/ImmigirationVisa";
import AustralianImmigrationGuidelines from "./Pages/immaus";
import UKVisaGuidelines from "./Pages/immuk";
import NewZealandImmigrationGuidelines from "./Pages/immnw";
import NetherlandsImmigrationGuidelines from "./Pages/immnl";
import CanadaImmigrationGuidelines from "./Pages/immca";
import JapanImmigrationGuidelines from "./Pages/immjp";
import SwitzerlandImmigrationGuidelines from "./Pages/immsl";
import GermanyImmigrationGuidelines from "./Pages/immgy";
import USImmigrationGuidelines from "./Pages/Immusa";
import ImmigirationDetails from "./Pages/ImmigirationDetails";
import EventDetail from "./Components/EventDetail";
import Feeder from "./Pages/Feeder/Feeder";
import Auto from "./Pages/Auto/Auto";
import Blogs from "./Pages/Blogs/Blogs";
import HealthDetails from "./Pages/Health/HealthDetails";

function App() {
  return (
    <div className="App">
      <GeoLocationProvider>
        <FlagProvider>
          <BrowserRouter>
            <Routes>
              <Route index element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/events" element={<Events />} />
              <Route path="/events/:id" element={<EventDetail />} />
              <Route path="home/events/:id" element={<EventDetail />} />
              <Route path="/health" element={<Health />} />
              <Route path="/health/:id" element={<HealthDetails />} />
              <Route path="/entertainment" element={<Entertainment />} />
              <Route path="/sports/*" element={<Sports />} />
              <Route path="/immigiration" element={<Immigiration />} />
              <Route
                path="/immigration-details/:id"
                element={<ImmigirationDetails />}
              />
              <Route
                path="/immigiration/india"
                element={<ImmigirationVisa />}
              />
              <Route
                path="/immigiration/usa"
                element={<USImmigrationGuidelines />}
              />
              <Route path="/immigiration/uk" element={<UKVisaGuidelines />} />
              <Route
                path="/immigiration/australia"
                element={<AustralianImmigrationGuidelines />}
              />
              <Route
                path="/immigiration/NewZealand"
                element={<NewZealandImmigrationGuidelines />}
              />
              <Route
                path="/immigiration/Netherlands"
                element={<NetherlandsImmigrationGuidelines />}
              />
              <Route
                path="/immigiration/canada"
                element={<CanadaImmigrationGuidelines />}
              />
              <Route
                path="/immigiration/japan"
                element={<JapanImmigrationGuidelines />}
              />
              <Route
                path="/immigiration/Switzerland"
                element={<SwitzerlandImmigrationGuidelines />}
              />
              <Route
                path="/immigiration/germany"
                element={<GermanyImmigrationGuidelines />}
              />
              <Route path="/technology/*" element={<Technology />} />

              <Route path="/news/:id" element={<MasterDetails />} />
              <Route path="home/sports/:id" element={<SportsDetail />} />

              <Route
                path="/entertainment/movies/:id"
                element={<MovieDetails />}
              />
              <Route path="/auto" element={<Auto />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/feeder/*" element={<Feeder />} />
            </Routes>
          </BrowserRouter>
        </FlagProvider>
      </GeoLocationProvider>
    </div>
  );
}

export default App;
