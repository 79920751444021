import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import NavBar from "../../Components/NavBar/NavBar";
import HealthMaster from "../../Components/HealthPage/HealthMaster";

const Health = () => {
  const [healthData, setHealthData] = useState([]); // Initialize healthData as an array
  const topicIds = [530, 531, 532, 533, 534, 536, 537, 538, 539, 540, 541, 544]; // Add the topic IDs you want to retrieve

  useEffect(() => {
    // Fetch data for each topic when the component mounts
    const fetchData = async () => {
      const topicsData = [];

      for (const topicId of topicIds) {
        try {
          const apiUrl = `https://health.gov/myhealthfinder/api/v3/topicsearch.json?lang=en&topicId=${topicId}`;
          const response = await axios.get(apiUrl, {
            params: {
              Type: "topic",
            },
          });

          const data = response.data;
          topicsData.push(data.Result.Resources.Resource[0]);
        } catch (error) {
          console.error("Error fetching health data:", error);
        }
      }

      setHealthData(topicsData);
    };

    fetchData();
  }, []);

  // Helper function to truncate text
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };
  const isLargeScreen = window.innerWidth >= 992; // Screen size handling
  const [loading] = useState(false); // No API call, so no loading state
  const autoData = [
    {
      id: 1,
      TH_Title: "How to Eat Healthier with Meatless Monday",
      TH_Lead:
        "Eating more fruits, vegetables, beans, and whole grains can help you stay healthy and live longer. ",
      TH_Image:
        "https://www.mondaycampaigns.org/wp-content/uploads/2021/04/BBQ-Chickpea-Veggie-Bowls-crop-675x0-c-default.jpg",
      BA_Category: "SUV",
      TH_Date: "2024-11-01",
    },
    {
      id: 2,
      TH_Title:
        "Your Guide to Cooking, Eating, and Shopping for Plant-based Meats",
      TH_Lead:
        "Diversifying your diet with more plant-based meat and dairy products is a delicious way to stay healthy, help the planet, and keep your taste buds excited.",
      TH_Image:
        "https://www.mondaycampaigns.org/wp-content/uploads/2021/12/woman-cooking-stir-fry-vegetables-675x0-c-default.png",
      BA_Category: "Sedan",
      TH_Date: "2024-11-10",
    },
    {
      id: 3,
      TH_Title: "Plant-Based Foods Packed with Protein",
      TH_Lead:
        "Educate and inspire with these graphics highlighting plant-based foods packed with protein.",
      TH_Image:
        "https://www.mondaycampaigns.org/wp-content/uploads/2021/05/hospital-doctor-food-photo-feature-675x0-c-default.jpg",
      BA_Category: "Hatchback",
      TH_Date: "2024-11-15",
    },
    {
      id: 4,
      TH_Title: "More plants. Less meat. Less diabetes.",
      TH_Lead:
        "In honor of American Diabetes Month, we’re highlighting how a diet with less meat and more plants can help lower the risk of developing type 2 diabetes.",
      TH_Image:
        "https://www.mondaycampaigns.org/wp-content/uploads/2020/09/mm-immune-e1630680901432-675x0-c-default.png",
      BA_Category: "Sports Car",
      TH_Date: "2024-11-20",
    },
    {
      id: 5,
      TH_Title:
        "Huge offers on Virtus, City, Slavia, Verna, Ciaz mid-sedans as sales plummet",
      TH_Lead:
        "The mid-size sedan segment in India includes models like the Volkswagen Virtus, Hyundai Verna, Honda City, Skoda Slavia and Maruti Suzuki Ciaz.",
      TH_Image:
        "https://akm-img-a-in.tosshub.com/indiatoday/images/story/202411/volkswagen-virtus-222314382-16x9_0.jpg?VersionId=SE7AKr78Blst_KgvmvkeFlc8IoLzFjAH&size=690:388",
      BA_Category: "Convertible",
      TH_Date: "2024-11-25",
    },
  ];

  // Split data for top picks and remaining
  const topPicks = autoData.slice(0, 4); // First 4 items

  return (
    <>
      <NavBar />
      <HealthMaster />
      {/* <section className="my-2">
        <Container fluid>
          <Row className="py-2">
            <Col xs={12} className="px-2">
              <h4 className="fw-bolder">Health Picks</h4>
            </Col>

          
            <Col
              style={{ backgroundColor: "#ffffff" }}
              className="p-2"
              xs={{ order: 1 }}
            >
              {topPicks.length > 0 && (
                <NavLink
                  as={Link}
                  to={`/health/${topPicks[0].id}`}
                  className=""
                  key={topPicks[0].id}
                >
                  <div className="card border-0">
                    <img
                      src={topPicks[0].TH_Image}
                      className="card-img-top"
                      alt={topPicks[0].TH_Title}
                    />
                    <div className="card-body px-0">
                      <h5 className="text-primary-emphasis fw-bold">
                        {topPicks[0].TH_Title}
                      </h5>
                      <p className="card-title fw-500">{topPicks[0].TH_Lead}</p>
                    </div>
                  </div>
                </NavLink>
              )}
            </Col>

            
            <Col
              xs={{ order: 2 }}
              md={12}
              lg={6}
              style={{ backgroundColor: "#ffffff" }}
              className="p-2"
            >
              {topPicks.slice(1).map((item) => (
                <NavLink
                  as={Link}
                  to={`/health/${item.id}`}
                  className="mt-2 mb-3"
                  key={item.id}
                >
                  <div className="card border-0 rounded-0">
                    <div className="row g-0">
                      <div className="col-xs-12 col-lg-5">
                        <img
                          src={item.TH_Image}
                          className="card-img-right img-fluid rounded-3 mb-3"
                          alt={item.TH_Title}
                          style={{
                            height: isLargeScreen ? "130px" : "auto",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="col-xs-12 col-lg-7 p-0">
                        <div className="card-body px-0 px-lg-2 pt-0 mt-0">
                          <p
                            className="text-primary-emphasis fw-bold mb-0"
                            style={{ fontSize: "13px" }}
                          >
                            <span>{item.TH_Title}</span>
                          </p>
                          <p
                            className="card-title fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            {item.TH_Lead}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              ))}
            </Col>
          </Row>
        </Container>
      </section> */}
      <div className="" style={{ backgroundColor: "#eeeeee" }}>
        <Container flui className="col-lg-11">
          <h4 className="pt-3 ms-2 fw-bolder">Health Tips </h4>
          {healthData.length > 0 ? (
            <Row className="g-3">
              {healthData.map((topic, index) => (
                <Col md={6} sm={12} lg={3} key={index}>
                  <Card className="border-0 shadow">
                    <Card.Img variant="top" src={topic.ImageUrl} />
                    <Card.Body className="p-2" style={{ minHeight: "100px" }}>
                      <Card.Text>
                        <h6 className="fw-bold">
                          {" "}
                          {truncateText(`${topic.Title}`, 25)}
                        </h6>
                        <small>
                          {" "}
                          {truncateText(`${topic.Categories}`, 25)}
                        </small>
                      </Card.Text>
                      <Card.Text></Card.Text>
                      <div className="text-end">
                        <Link
                          to={`/health/${topic.Id}`}
                          className="btn btn-dark btn-sm"
                        >
                          Read More
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <p>Loading health data...</p>
          )}
        </Container>
      </div>
    </>
  );
};

export default Health;
